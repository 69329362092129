import { useContext, useEffect, useState } from "react";
import { RedeemCoupon } from "../../service/RedeemCoupon";
// import AlertModal from "../Models/AlertModel";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { LoaderContext } from "../../context/LoaderContext";
import { sendMessage } from "../../service/SendMessage";
import { smsMessage } from "../../service/smsMessage";
import { getStatus } from "../../service/GetStatus";
import { activateCoupon } from "../../service/ActivateCoupon";
import { mobileUpdate } from "../../service/mobileUpdate";
import { toast } from "react-toastify";

import axios from "axios";
export default function ProductItem({
  coupon,
  index,
  handleCoupons,
  currPage,
}) {
  const [show, setShow] = useState(false);
  const [pendingmodal, setPendingModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [cancelmodal, setCancelModal] = useState(false);
  const [refreshButton, setRefreshButton] = useState(false);
  const [errorModal, setErrorModal] = useState({ state: false });
  const [notExistModal, setnotExistModal] = useState(false);
  const [copied, setCopied] = useState(false);
  const [copiedTwo, setCopiedTwo] = useState(false);
  const [resultModal, setResultModal] = useState({ state: false });
  const [whatsappModal, setWhatsappModal] = useState({ state: false });
  const [smsModal, setSmsModal] = useState({ state: false });
  const [statusModal, setStatusModal] = useState(false);
  const [activationModal, setActivationModal] = useState(false);
  const [activationCoupon, setActivationCoupon] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [registerModal, setRegisterModal] = useState(false);
  // const [mobile, setMobile] = useState(null);
  const [mobile, setMobile] = useState({ mobile: "" });

  const { setLoading } = useContext(LoaderContext);

  const handleClose = () => {
    setShow(false);
  };

  const handleclose = () => {
    setPendingModal(false);
  };
  const handleCLose = () => {
    setCancelModal(false);
  };

  const closeRegisterModal = () => {
    setRegisterModal(false);
  };

  useEffect(() => {
    setCopied(false);
    setCopiedTwo(false);
  }, [coupon]);

  const handleResultModalClose = () => {
    setResultModal({ state: false });
    setErrorModal({ state: false });
    setnotExistModal(false);
  };

  const handleRedeem = async () => {
    setLoading(true);
    const response = await RedeemCoupon();
    
    if (response?.data?.success) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
  
      if (response.data.data.data.getData.status === 1) {
        setModal(true);
      } else if (response.data.data.data.getData.status === 4) {
        setCancelModal(true)
      } else {
        setResultModal({ state: true, card: response.data.data.data });
      }
    } else if (response.status === 201) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setErrorModal({ state: true });
    } else {
      setLoading(false);
    }
    handleCoupons();
  };
  

  const copyText = (text, id) => {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    textarea.setAttribute("readonly", "");
    textarea.style.position = "absolute";
    textarea.style.left = "-9999px";
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    if (id === 1) {
      setCopied(true);
    } else {
      setCopiedTwo(true);
    }
  };

  const handleWhatsapp = async (id) => {
    setLoading(true);
    const response = await sendMessage(id);
    setLoading(false);
    if (response.data.success) {
      setWhatsappModal({ state: true });
    }
  };

  const handleSMS = async (id) => {
    setLoading(true);
    const response = await smsMessage(id);
    setLoading(false);
    if (response.data.success) {
      setSmsModal({ state: true });
    }
  };

  const handleStatusCheck = async () => {
    setLoading(true);
    const response = await getStatus();
    setLoading(false);
    console.log("response", response);

    if (response && response.data.success && response.data.data.data) {
      const status = response.data.data.data.qwickcilver_status;

      if (status == "complete") {
        setStatusModal({ status: true });
      } else if (status == "pending") {
        setPendingModal(true);
      } else if (status == "canceled") {
        setCancelModal(true);
      } else {
        // Handle other statuses or conditions as needed
        setStatusModal({ status: false });
      }
    } else {
      if (!response.data.data) {
        setnotExistModal(true);
      }
    }

    setTimeout(() => {  
      handleCoupons();
    }, 1000);
  };

  const handleActivate = async () => {
    setLoading(true);
    const response = await activateCoupon();
  
    if (response && response.data.data.data.getData) {
      const status = response.data.data.data.getData.status;
      setActivationCoupon(response.data.data.data);
  
      handleCoupons();
      setLoading(false);
  
      if (response.data.success && status != 4) {
        setActivationModal({ status: true });
      } else if ( response.data.success && status === 4) {
        setCancelModal(true)
      } else {
        setActivationModal({ status: false });
      }
    }
  };
  

  useEffect(() => {
    console.log(resultModal);
  }, [resultModal]);

  // const currentTime = new Date(); // Current time
  // const specificTime = new Date("2023-06-06T10:30:00"); // Specific time
  // const differenceInMilliseconds = specificTime - currentTime;
  // const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);
  // const differenceInMinutes = Math.floor(differenceInSeconds / 60);
  // const differenceInHours = Math.floor(differenceInMinutes / 60);
  // const differenceInDays = Math.floor(differenceInHours / 24);

  useEffect(() => {
    axios
      .get(
        `${
          process.env.REACT_APP_BASEURL
        }/api/user/loggedUser/detail?page=${currPage}&size=${10}`,
        {
          headers: {
            "x-access-token": localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        console.log("////", res.data.response.mobile);
        if (res.data.success && res.data.response.mobile) {
          setOpenModal(false);
        } else {
          setOpenModal(true);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [refreshButton]);

  const handleRegisterModal = () => {
    setRegisterModal(true);
  };

  // const UpdateMobile = async (mobile) => {
  //   console.log(mobile);
  //   const response = await mobileUpdate(mobile);
  //   setLoading(false);
  //   if (response && response?.data?.success) {
  //     closeRegisterModal();
  //   }else{
  //     alert("please enter mobile number")
  //   }
  // };

  const UpdateMobile = async (mobile) => {
    console.log(mobile, "number");
    const mobileInput = mobile.mobile;
    const validationMessage = document.getElementById(
      "mobileValidationMessage"
    );

    if (!mobileInput) {
      validationMessage.innerText = "Please enter a mobile number";
      validationMessage.style.color = "red";
      return;
    }
    const mobilePattern = /^[6-9]\d{9}$/;

    if (!mobilePattern.test(mobileInput)) {
      validationMessage.innerText =
        "Please enter a valid 10-digit number starting with 6, 7, 8, or 9";
      validationMessage.style.color = "red";
      return;
    }
    validationMessage.innerText = "";
    validationMessage.style.color = "transparent";

    setLoading(true);
    console.log("Sending mobile data:", mobile);

    try {
      const response = await mobileUpdate(mobile);
      setLoading(false);

      if (response && response.success) {
        closeRegisterModal();
        toast.success(response.message);
        setRefreshButton(true);
      } else {
        console.error("API call failed:", response);
        toast(response.message || "Failed to update mobile number");
      }
    } catch (error) {
      console.error("API call error:", error);
      toast("An error occurred while updating the mobile number");
    }
  };

  useEffect(() => {
    console.log(activationCoupon);
  }, [activationCoupon]);

  return (
    <div className="mc-product-item">
      <div className="mc-product-serial-number">
        {(currPage - 1) * 10 + index + 1}.
      </div>
      <div className="mc-product-image-container">
        <img
          className="mc-product-image"
          src={coupon?.image || "images/coupon.png"}
          alt=""
        />
        <div className="mc-product-desc-container mc-coupon-code-container">
          <h3 className="mc-product-name">{coupon?.coupon_code || "__"}</h3>
          <div className="mc-price-container">
            {/* <span className="min-price">{coupon?.currency?.symbol || ""}{coupon?.cost || "₹100"}</span> */}
            <span>Points: </span>
            <span className="max-price">
              {coupon?.currency?.symbol || ""}
              {coupon?.price_point || ""}
            </span>
          </div>
        </div>
      </div>

      <div className="mc-product-desc-container description">
        <p className="mc-coupon-label">Title</p>
        <h3 className="mc-coupon-title">{coupon?.title || "__"}</h3>
        <p className="mc-coupon-label">Description</p>
        <h3 className="mc-coupon-description">
          {coupon.description
            ? coupon.description.length > 150
              ? `${coupon.description.slice(0, 150)}...`
              : coupon.description
            : "__"}
        </h3>
      </div>

      <div className="mc-product-desc-container description">
        <p className="mc-coupon-label">SKU</p>
        <h3 className="mc-coupon-title">{coupon?.sku || "__"}</h3>
        <p className="mc-coupon-label">Brand Name</p>
        <h3 className="mc-coupon-description">{coupon?.brand_name || "__"}</h3>
        {coupon.expireAt && (
          <>
            <p className="mc-coupon-label">Expires At</p>
            <h3 className="mc-coupon-description">
              {new Date(coupon.expireAt).toLocaleString() || "__"}
            </h3>
          </>
        )}
      </div>

      <div className="mc-product-desc-container description">
        {coupon?.status === 2 && coupon?.cardNumber && (
          <>
            <p className="mc-coupon-label">Card Number</p>
            <h3 className="mc-coupon-card">
              <span className="mc-coupon-card-text">
                {coupon?.cardNumber || "___"}
              </span>
              <span onClick={() => copyText(coupon?.cardNumber, 1)}>
                {!copied ? (
                  <i className="fa-solid fa-copy"></i>
                ) : (
                  <i className="fa-solid fa-check"></i>
                )}
              </span>
            </h3>
          </>
        )}
        {coupon?.status === 2 && coupon?.cardPin && (
          <>
            <p className="mc-coupon-label">Card Pin</p>
            <h3 className="mc-coupon-pin">
              <span className="mc-coupon-pin-text">{coupon?.cardPin}</span>
              <span onClick={() => copyText(coupon?.cardPin, 2)}>
                {!copiedTwo ? (
                  <i className="fa-solid fa-copy"></i>
                ) : (
                  <i className="fa-solid fa-check"></i>
                )}
              </span>
            </h3>
          </>
        )}
        {coupon?.status === 2 && coupon?.activationUrl && (
          <>
            <p className="mc-coupon-label">Activation Url</p>
            <h3 className="mc-coupon-pin">
              <span className="mc-coupon-card-text">
                {coupon?.activationUrl || "__"}
              </span>
              <span
                onClick={() => window.open(coupon?.activationUrl, "_blank")}
              >
                <i class="fa-solid fa-arrow-up-right-from-square"></i>
              </span>
            </h3>
          </>
        )}
        {coupon?.status === 2 && coupon?.activationCode && (
          <>
            <p className="mc-coupon-label">Activation Code</p>
            <h3 className="mc-coupon-pin">
              <span className="mc-coupon-pin-text">
                {coupon?.activationCode || "__"}
              </span>
              <span onClick={() => copyText(coupon?.activationCode, 2)}>
                {!copiedTwo ? (
                  <i className="fa-solid fa-copy"></i>
                ) : (
                  <i className="fa-solid fa-check"></i>
                )}
              </span>
            </h3>
          </>
        )}
      </div>
      <div className="mc-coupon-container">
        {coupon && coupon?.status === 0 && (
          <button
            className=" mc-coupon-redeem-button"
            onClick={() => {
              setShow(true);
            }}
          >
            Redeem Now
          </button>
        )}
        {coupon && coupon?.status === 2 && (
          <div>
            <button className="mc-coupon-redeem-button redeemed">
              Redeemed
            </button>
            <br />
            {openModal ? (
              <>
                <button
                  className="register-button"
                  onClick={handleRegisterModal}
                  style={{
                    marginTop: "10px",
                    width: "130px",
                    padding: "8px",
                    borderRadius: "8px",
                    color: "white",
                    border: "none",
                  }}
                >
                  <i
                    className="fa-brands fa-whatsapp"
                    style={{ fontSize: "20px" }}
                  ></i>
                </button>
                <button
                  className="register-button"
                  onClick={handleRegisterModal} // Add the SMS click handler
                  style={{
                    marginTop: "10px",
                    width: "130px",
                    padding: "8px",
                    borderRadius: "8px",
                    color: "white",
                    border: "none",
                  }}
                >
                  <i className="fas fa-sms" style={{ fontSize: "20px" }}></i>
                </button>
              </>
            ) : (
              <div style={{ display: "flex", gap: "10px" }}>
                <button
                  className="mc-coupon-redeem-button whatsapp-button"
                  onClick={() => {
                    handleWhatsapp(coupon?.id);
                  }}
                >
                  <i className="fa-brands fa-whatsapp"></i>
                </button>
                {/* <button
                  className="mc-coupon-redeem-button sms-button"
                  onClick={() => {
                    handleSMS(coupon?.id); // Add the SMS click handler
                  }}
                >
                  <i className="fas fa-sms"></i>
                </button> */}
              </div>
            )}
          </div>
        )}
        {
          <Modal show={registerModal} onHide={closeRegisterModal} centered>
            <Modal.Header closeButton>
              <Modal.Title>Submit Your Mobile Number</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: "50px", width: "100%" }}>
              <label
                className="required-field"
                style={{ margin: "3px", fontWeight: "bold" }}
                htmlFor=""
              >
                Number
              </label>
              <input
                style={{
                  borderRadius: "8px",
                  border: "1px solid black",
                  padding: "10px",
                  width: "100%",
                }}
                placeholder="Enter Your Number"
                type="number"
                name=""
                id=""
                maxlength="10"
                onChange={(e) => {
                  setMobile({
                    ...mobile,
                    mobile: e.target.value,
                  });
                }}
              />
              <div
                id="mobileValidationMessage"
                style={{ margin: "5px", fontWeight: "bold" }}
              ></div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                style={{ padding: "8px 18px" }}
                variant="secondary"
                onClick={closeRegisterModal}
              >
                Cancel
              </Button>
              <Button
                type="button"
                className="btn btn-outline"
                id="register_submit_button"
                onClick={() => {
                  UpdateMobile(mobile);
                }}
              >
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
        }

        {coupon && coupon?.status === 3 && !coupon?.cardNumber && (
          <div>
            <button
              className="mc-btn-status"
              onClick={() => {
                handleActivate();
              }}
            >
              Activate
            </button>
          </div>
        )}
        {(coupon && coupon?.status === 1) && (
          <div>
            <button
              className="mc-btn-status"
              onClick={() => {
                handleStatusCheck();
              }}
            >
              Check Status
            </button>
          </div>
        )}

        {coupon && coupon?.status === 4 && (
          <button disabled className="mc-btn-status btn btn-secondary">
            Cancelled
          </button>
        )}
      </div>
      {pendingmodal && (
        <Modal show={pendingmodal} onHide={handleclose} style={{ top: "35%" }}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body
            style={{
              textAlign: "center",
              justifyContent: "center",
              fontWeight: "bold",
              fontSize: "17px",
            }}
          >
            Please Try again After Sometime
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{ padding: "8px 18px" }}
              variant="secondary"
              onClick={handleclose}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {cancelmodal && (
        <Modal show={cancelmodal} onHide={handleCLose} style={{ top: "35%" }}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body
            style={{
              textAlign: "center",
              justifyContent: "center",
              fontWeight: "bold",
              fontSize: "17px",
            }}
          >
            Your Coupon is cancelled, Please try again Later.
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{ padding: "8px 18px" }}
              variant="primary"
              onClick={handleCLose}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {show && (
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Are you sure to redeem this coupon?</Modal.Title>
          </Modal.Header>
          {/* <Modal.Body></Modal.Body> */}
          <Modal.Footer>
            <Button
              style={{ padding: "8px 18px" }}
              variant="secondary"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              className="confirm-button"
              variant="danger"
              onClick={() => {
                handleRedeem();
                handleClose();
              }}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {resultModal.state && (
        <Modal
          show={resultModal.state}
          onHide={handleResultModalClose}
          centered
        >
          <img className="mc-check-gif" src="images/check.gif" alt="" />
          <div className="mc-redeemed-coupon-details">
            <h3 className="mc-coupon-redeemed-text">
              Congratulations! Your Coupon is now Activated
            </h3>
            <p>Please find the details below.</p>
            <div className="mc-product-desc-container description">
              {resultModal?.card?.getData?.cardNumber !== null && (
                <div>
                  <p className="mc-coupon-label">Card Number</p>
                  <h3 className="mc-coupon-card">
                    <span className="mc-coupon-card-text">
                      {resultModal?.card?.getData?.cardNumber}
                    </span>
                    <span
                      onClick={() =>
                        copyText(resultModal?.card?.getData?.cardNumber, 1)
                      }
                    >
                      {!copied ? (
                        <i className="fa-solid fa-copy"></i>
                      ) : (
                        <i className="fa-solid fa-check"></i>
                      )}
                    </span>
                  </h3>
                </div>
              )}
              {resultModal?.card?.getData?.cardPin !== null && (
                <div>
                  <p className="mc-coupon-label">Card Pin</p>
                  <h3 className="mc-coupon-pin">
                    <span className="mc-coupon-pin-text">
                      {resultModal?.card?.getData?.cardPin}
                    </span>
                    <span
                      onClick={() =>
                        copyText(resultModal?.card?.getData?.cardPin, 2)
                      }
                    >
                      {!copiedTwo ? (
                        <i className="fa-solid fa-copy"></i>
                      ) : (
                        <i className="fa-solid fa-check"></i>
                      )}
                    </span>
                  </h3>
                </div>
              )}
              {resultModal?.card?.getData?.activationUrl && (
                <>
                  <p className="mc-coupon-label">Activation Url</p>
                  <h3 className="mc-coupon-card">
                    <span className="mc-coupon-card-text">
                      {resultModal?.card?.getData?.activationUrl}
                    </span>
                    <span
                      onClick={() =>
                        window.open(
                          resultModal?.card?.getData?.activationUrl,
                          "_blank"
                        )
                      }
                    >
                      <i class="fa-solid fa-arrow-up-right-from-square"></i>
                    </span>
                  </h3>
                </>
              )}
              {resultModal?.card?.getData?.activationCode && (
                <>
                  <p className="mc-coupon-label">Activation Code</p>
                  <h3 className="mc-coupon-card">
                    <span className="mc-coupon-card-text">
                      {resultModal?.card?.getData?.activationCode}
                    </span>
                    <span
                      onClick={() =>
                        copyText(resultModal?.card?.getData?.activationCode, 1)
                      }
                    >
                      {!copied ? (
                        <i className="fa-solid fa-copy"></i>
                      ) : (
                        <i className="fa-solid fa-check"></i>
                      )}
                    </span>
                  </h3>
                </>
              )}
            </div>
          </div>
          <Modal.Footer className="mc-modal-footer">
            <Button
              className="mc-coupon-redeem-button redeemed-button"
              variant="success"
              onClick={handleResultModalClose}
            >
              Ok, got it!
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {/* Error modal */}
      {errorModal.state && (
        <Modal show={errorModal.state} onHide={handleResultModalClose} centered>
          <img className="mc-check-gif" src="images/cross.gif" alt="" />
          <div className="mc-redeemed-coupon-details">
            <h3 className="mc-coupon-redeemed-text">Oh! Sorry !</h3>
            <p>Something went wrong, please try again after sometimes.</p>
            <div className="mc-product-desc-container description"></div>
          </div>

          {/* <Modal.Body></Modal.Body> */}
          <Modal.Footer className="mc-modal-footer">
            <Button
              className="mc-coupon-redeem-button redeemed-button"
              variant="danger"
              onClick={handleResultModalClose}
            >
              Ok, got it!
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {notExistModal && (
        <Modal show={notExistModal} onHide={handleResultModalClose} centered>
          <img className="mc-check-gif" src="images/cross.gif" alt="" />
          <div className="mc-redeemed-coupon-details">
            <h3 className="mc-coupon-redeemed-text">Oh! Sorry !</h3>
            <p>This Coupon does not exist</p>
            <div className="mc-product-desc-container description"></div>
          </div>

          {/* <Modal.Body></Modal.Body> */}
          <Modal.Footer className="mc-modal-footer">
            <Button
              className="mc-coupon-redeem-button redeemed-button"
              variant="danger"
              onClick={handleResultModalClose}
            >
              Ok, got it!
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {whatsappModal.state && (
        <Modal
          show={whatsappModal.state}
          onHide={handleResultModalClose}
          centered
        >
          <img className="mc-check-gif" src="images/check.gif" alt="" />
          <div className="mc-redeemed-coupon-details">
            <h3 className="mc-coupon-redeemed-text">Whoohoo! Message Sent !</h3>
            {/* <p>Please find your coupon creds.</p> */}
            {/* <div className="mc-product-desc-container description">
                    <h3 className="mc-coupon-card"><span className="mc-coupon-card-text">{coupon?.cardNumber || "Null"}</span><span onClick={() => copyText(coupon?.cardNumber, 1)}>{!copied ? <i className="fa-solid fa-copy"></i> : <i className="fa-solid fa-check"></i>}</span></h3>
                    <h3 className="mc-coupon-pin"><span className="mc-coupon-pin-text">{coupon?.cardPin || "Null"}</span><span onClick={() => copyText(coupon?.cardPin, 2)}>{!copiedTwo ? <i className="fa-solid fa-copy"></i> : <i className="fa-solid fa-check"></i>}</span></h3>
                </div> */}
          </div>

          {/* <Modal.Body></Modal.Body> */}
          <Modal.Footer className="mc-modal-footer">
            <Button
              className="mc-coupon-redeem-button redeemed-button"
              variant="success"
              onClick={() => {
                setWhatsappModal({ state: false });
              }}
            >
              Ok, got it!
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {smsModal.state && (
        <Modal show={smsModal.state} onHide={handleResultModalClose} centered>
          <img className="mc-check-gif" src="images/check.gif" alt="" />
          <div className="mc-redeemed-coupon-details">
            <h3 className="mc-coupon-redeemed-text">Whoohoo! Message Sent !</h3>
            {/* <p>Please find your coupon creds.</p> */}
            {/* <div className="mc-product-desc-container description">
                    <h3 className="mc-coupon-card"><span className="mc-coupon-card-text">{coupon?.cardNumber || "Null"}</span><span onClick={() => copyText(coupon?.cardNumber, 1)}>{!copied ? <i className="fa-solid fa-copy"></i> : <i className="fa-solid fa-check"></i>}</span></h3>
                    <h3 className="mc-coupon-pin"><span className="mc-coupon-pin-text">{coupon?.cardPin || "Null"}</span><span onClick={() => copyText(coupon?.cardPin, 2)}>{!copiedTwo ? <i className="fa-solid fa-copy"></i> : <i className="fa-solid fa-check"></i>}</span></h3>
                </div> */}
          </div>

          {/* <Modal.Body></Modal.Body> */}
          <Modal.Footer className="mc-modal-footer">
            <Button
              className="mc-coupon-redeem-button redeemed-button"
              variant="success"
              onClick={() => {
                setSmsModal({ state: false });
              }}
            >
              Ok, got it!
            </Button>
          </Modal.Footer>
        </Modal>
      )}

<Modal centered show={modal}>
        <Modal.Body>
            <div>
              <img
                className="mc-pending-image"
                src="/images/pending.jpg"
                alt=""
              />
              <p className="mc-coupon-status-text">Your Coupon is in under process. Please Check Status.</p>
            </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => {
              setModal(false);
            }}
            className="mc-btn-status"
            style={{ margin: "10px 10px 10px auto" }}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>

      <Modal centered show={statusModal}>
        <Modal.Body>
          {statusModal.status && (
            <div>
              <img
                className="mc-pending-image"
                src="/images/pending.jpg"
                alt=""
              />
              <p className="mc-coupon-status-text">Your coupon is ready !</p>
            </div>
          )}
          {!statusModal.status && (
            <div>
              <img
                className="mc-pending-image"
                src="/images/error.jpg"
                alt=""
              />
              <p className="mc-coupon-status-text">
                Error while checking status.
                <br />
                We will send you a notification when it is ready.
              </p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => {
              setStatusModal(false);
            }}
            className="mc-btn-status"
            style={{ margin: "10px 10px 10px auto" }}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <Modal centered show={activationModal?.status}>
        {activationModal?.status && (
          <Modal.Body>
            <div className="mc-container-activation">
              <img className="mc-check-gif" src="images/check.gif" alt="" />
              <div className="mc-redeemed-coupon-details">
                <h3 className="mc-coupon-redeemed-text">
                  Whoohoo! Coupon Redeemed !
                </h3>
                <p>Please find your coupon creds.</p>
                <div className="mc-product-desc-container description">
                  {(activationCoupon &&
                    activationCoupon?.getData?.cardNumber) && (
                      <div>
                        <p className="mc-coupon-label">Card Number</p>
                        <h3 className="mc-coupon-card">
                          <span className="mc-coupon-card-text">
                            {activationCoupon?.getData?.cardNumber}
                          </span>
                          <span
                            onClick={() =>
                              copyText(activationCoupon?.getData?.cardNumber, 1)
                            }
                          >
                            {!copied ? (
                              <i className="fa-solid fa-copy"></i>
                            ) : (
                              <i className="fa-solid fa-check"></i>
                            )}
                          </span>
                        </h3>
                      </div>
                    )}
                  {(activationCoupon &&
                    activationCoupon?.getData?.cardPin) && (
                    <div>
                      <p className="mc-coupon-label">Card Pin</p>
                      <h3 className="mc-coupon-pin">
                        <span className="mc-coupon-pin-text">
                          {activationCoupon?.getData?.cardPin}
                        </span>
                        <span
                          onClick={() => copyText(activationCoupon?.getData?.cardPin, 2)}
                        >
                          {!copiedTwo ? (
                            <i className="fa-solid fa-copy"></i>
                          ) : (
                            <i className="fa-solid fa-check"></i>
                          )}
                        </span>
                      </h3>
                    </div>
                  )}
                  {activationCoupon && activationCoupon?.getData?.activationUrl && (
                    <>
                      {activationCoupon?.getData?.activationUrl && (
                        <p className="mc-coupon-label">Activation Url</p>
                      )}
                      <h3 className="mc-coupon-card">
                        <span className="mc-coupon-card-text">
                          {activationCoupon?.getData?.activationUrl}
                        </span>
                        <span
                          onClick={() =>
                            window.open(
                              activationCoupon?.getData?.activationUrl,
                              "_blank"
                            )
                          }
                        >
                          <i class="fa-solid fa-arrow-up-right-from-square"></i>
                        </span>
                      </h3>
                    </>
                  )}
                  {activationCoupon?.activationCode && (
                    <>
                      {activationCoupon?.getData?.activationCode && (
                        <p className="mc-coupon-label">Activation Code</p>
                      )}
                      <h3 className="mc-coupon-card">
                        <span className="mc-coupon-card-text">
                          {activationCoupon?.getData?.activationCode}
                        </span>
                        <span
                          onClick={() =>
                            copyText(activationCoupon?.getData?.activationCode, 1)
                          }
                        >
                          {!copied ? (
                            <i className="fa-solid fa-copy"></i>
                          ) : (
                            <i className="fa-solid fa-check"></i>
                          )}
                        </span>
                      </h3>
                    </>
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
        )}
        <Modal.Footer
          onClick={() => {
            setActivationModal(false);
            handleCoupons();
          }}
        >
          <button 
            className="mc-btn-status"
            style={{ margin: "10px 10px 10px auto" }}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
